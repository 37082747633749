.sidr {
  /* Default Settings */
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  background:theme-color('blue');
  color: white;
  border-right: 1px solid darken(theme-color('blue'), 2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  .sidr-inner  {
    > ul{
      li:not(:last-child){
        border-bottom: 1px solid darken(theme-color('blue'), 12);
      }
      li:not(:first-child){
        border-top: 1px solid lighten(theme-color('blue'), 9);
      }

    }
    &:first-child{
      li:nth-child(3){
        border-bottom: 0;
      }
    }
  }


}

.sidr-class-ancestor{
  >a{
    color: theme-color('primary') !important;
  }
}
@keyframes slide {
  100% { left: 0; }
}

.sidr.right {
  left: auto;
  right: -260px;
}
.sidr.left {
  left: -260px;
  right: auto;


}

.sidr-class-icon-dropdown{
  .sidr-class-dropdown-toggle{
    display: flex;
    align-items: center;
  }
  >a:after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
}

.sidr .sidr-inner {
  padding: 0 0 15px
}
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}

.sidr ul {
  display: block;
  margin: 0 0 15px;
  padding: 0;
}
.sidr ul li {
  display: block;
  margin: 0;
  line-height: 48px;
  position: relative;
  .show{
    border-radius: 0;
    background-color: darken(theme-color('blue'), 10);
    a{
      line-height: 1.4;
      padding: 1rem .3rem;
      &:not(:last-child){
        border-bottom: 1px solid #000;
      }
    }
  }
}
.sidr ul li:hover,
          .sidr ul li.active,
          .sidr ul li.sidr-class-active {
  border-top: 0;
  line-height: 49px
}

.sidr ul li a{
  padding: 0 15px;
  display: block;
  text-decoration: none;
  color: white;
  font-family: $montserrat-medium;
}
.sidr ul li ul {
  border-bottom: 0;
  margin: 0
}
.sidr ul li ul li {
  line-height: 40px;
  font-size: 15px - 2
}


.sidr label {
  font-size: 15px - 2;
}

.sidr input[type=checkbox] {
  width: auto;
  display: inline;
  clear: none;
}
.sidr input[type=button],
  .sidr input[type=submit] {
  color: #f8f8f8;
  background: #333
}
.sidr input[type=button]:hover, .sidr input[type=submit]:hover {
  background: rgba(#333, .9);
}


.sidr-class-dropdown-menu{
  @extend .dropdown-menu;
}

.sidr-class-menu li:last-child{
  display: none;
}
