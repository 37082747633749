.fobi-form{
  padding: 1rem;
  background-color: darken(white, 3);
  border: 1px solid #eee;

  .form-group {
    display: flex;
    flex-wrap: wrap;

    label {
      font-weight: bold;
      @include make-col(3);
    }

    label + div {
      @include make-col(9);
    }
  }

  .form-required-field-message-wrapper {
    padding: 0.5rem;
    background-color: rgba(aqua, .2);
    color: darkcyan;
    margin-bottom: 1rem;
  }

  .form-control {
    border-radius: 0;
  }
}
