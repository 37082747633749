.plugin__search{
  @include make-row();
  @include media-breakpoint-up(lg) {
    margin-top: 3rem;
  }
}
.frm__search{
  .wrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #eee;
    .wrapper{
      p{
        margin-bottom: 0.5rem;
        label, input{
          display: block;
          width: 100%;
        }
      }
      input{

      }
    }
  }
}
.search-results{
  ul{
    list-style-type: none;
    padding-left: 0;
  }
}
