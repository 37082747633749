.sidebar{
  background-color: #F9F7F7;
  padding: 2rem;
  h3{
    font-size :20px;
    color: #707070;
    text-transform: uppercase;
  }
  form{
    .btn{
      background-color: #707070;
    }
  }
  ul{
    list-style-type: none;
    padding-left: 0;
    li{
      span{
        display: none;
      }
    }
    a{
      color: inherit;
      text-decoration: none;
      transition: all .3s ease;
      display: block;
      line-height: 1.5;
      &:hover{
        color: theme-color('primary');
        transform: translateX(5px);
      }
    }
    ul{
      padding-left: 10px;

    }
  }
  .plugin{
    margin-bottom: 2rem;
  }
}


.related_posts{
  background-color: #F9F7F7;
  padding: 4rem 0;
  h2{
    text-transform: none;
    margin-bottom: 2rem;
  }
  h2, h3{
    font-family: $pacifico;
  }
  .post-item {
    min-height: 100%;
    .post-visual{
      box-shadow: 3px 3px 10px #00000029;
      a{
        display: block;
      }
    }
  }
  .post-item-wrapper{
    .list-inline {
      display: none;
    }
  }
}

.blog-list{
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 20px;

}

form{
  input, button{
    border-radius: 0  !important;
  }

}


.app-blog{
  .lead_content{
    margin-bottom: 10px;
    font-family: $montserrat-medium;
    padding: 1rem;
    background-color: darken(white, 3);
    border: 1px solid darken(white, 5);
  }
}
