.social-share-buttons{
  display: flex;
  a{
    width: 30px;
    height: 30px;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin-left: 5px;
    margin-right: 5px;
    &.facebook-share-button{
      background-color: $facebook;
    }
    &.twitter-share-button{
      background-color: $twitter;
    }
    &.linkedin-share-button{
      background-color: $linkedin;
    }
    &.email-share-button{
      background-color: theme-color('primary');
    }
  }
}
