.box{
  @include media-breakpoint-up(lg) {
    box-shadow: 3px 3px 6px #00000029;
    border: 1px solid #70707017;
  }
  background: #FFFFFF;
  padding: 1rem;
  p{
    font-family: $montserrat-medium;
    font-size: 16px;
    margin-top: 1.5rem;
  }
}


#footer{
  background-color: white;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .box{
    // margin-right: 3rem;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      #id_main_logo{
        flex-direction: column;
      }
    }
  }
  .title{
    font-family:  $montserrat-bold;
    color: #707070;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

}

.nav_footer{
  a{
    color: #707070;
    display: block;
    &:hover{
      color: theme-color('primary');
      text-decoration: none;
    }
  }
  ul{
    list-style-type: none;
    padding-left: 0;
  }
}

.logo-footer{
  .logo_text{
    span{
      display: block;
      color: #272727;
      font-size: 16px;
      &:first-child{
        font-family: $montserrat-bold;

      }
      &:last-child{
        font-family: $pacifico;

      }
    }
  }
}


.footer_site{
  @include media-breakpoint-up(lg) {
    padding-top: 466px;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 100px;
  }
  position: relative;
  &::before{
    content: "";
    position: absolute;
    background-image: url(../img/footer-accueil-paysan.jpg);
    background-size: cover;
    height: 360px;
    top: 0;
    left: 0;
    right: 0;
    @include media-breakpoint-down(lg) {
      height: 100px;
    }
  }
  .footer-bottom{
    background-color: darken(white,4);
    margin-top: 20px;
    @include media-breakpoint-down(md) {
      padding-top: 1rem;
      ul {
        flex-direction: column;
      }
      li{
        display: block;
        border-bottom: 1px solid  darken(white,8);
        border-top: 1px solid  darken(white,1);
        &:first-child{
          font-family: $montserrat-bold;
          font-size: 14px;
        }
        &:first-child{
          border-top: 0;
        }
      }
    }
    *{
      font-size: 13px;
      color: inherit;
      font-family: $montserrat-medium;
    }
    a{
      &:hover{
        color: theme-color('primary');
      }
    }
  }
}
