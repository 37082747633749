.engagements{
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.search_fnap{
  position: relative;
  z-index: 1;
  @include media-breakpoint-down(md) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: theme-color('blue');
    h2, p{color: white;}
  }
  input,select{
    appearance: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 6px #00000026;
    border: 1px solid #7070703B;
    min-width: 200px;
    padding: .5rem 1rem;

  }
  button.btn{
    border-radius: 8px !important;
  }
  button,input,select{
    height: 50px;
    margin: .4rem;
  }
  form{
    @include media-breakpoint-up(lg) {
      padding: 2rem 3rem;
    }
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      justify-content: center;
    }

  }
  .form_wrapper{
    display: flex;
    justify-content: center;

    .button{
      border-radius: 8px !important;
    }
    .bootstrap-select{
      margin-right: .8rem;
      box-shadow: 5px 5px 6px #00000026;
      border: 1px solid #7070703B;
      .filter-option{
        font-weight: normal;
        display: flex;
        height: 100%;
        align-items: center;
      }
      .filter-option-inner-inner{
        font-weight: normal !important;
        display: inline-flex;
      }
    }
    .dropdown-toggle.btn-light{
      background-color: white;
      font-weight: normal;
      font-family: $montserrat-regular;
      margin: 0;
      border: 0;
      height: 50px;
      border-radius: 0 !important;
      @include media-breakpoint-down(md) {
        margin-bottom: 0.4rem;
      }
    }
    select[multiple]{
      &:focus{
        height: 80px;
      }
      option:checked{
        color: green;
      }
    }
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      form{
        >*{
          min-width: 100%;
          margin-left: 0;
          margin-right: 0 !important;
        }
      }
    }
  }
}

.typeahead.dropdown-menu{
  li{
    padding: 1rem;
    border-bottom: 1px solid #eee;
    transition: all .3s ease;
    a{
      color: inherit;
      transition: all .3s ease;

    }
    &:hover{
      background-color: #eee;
      a{
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

.section{
  @include media-breakpoint-up(lg) {
    padding: 4rem 0;

  }
  padding: 1.6rem 0;

}

.services{
  @include media-breakpoint-up(lg) {
    padding-top: 7rem;
    padding-bottom: 4rem;

  }
  background-color: darken(white, 3);
  .list{
    @include media-breakpoint-up(lg) {

      margin-top: 4rem;
    }
    display: grid;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
    flex-wrap: wrap;
    width: 100%;
    .teaser{
      flex: 0 1 30%;
      padding: 1rem;
      margin-bottom: 1rem;
      display: flex;
      position: relative;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        text-align: center;
      }
      img{
        max-width: 150px;
        margin-bottom: 2rem;
      }
      &-content{
        padding-left: 10px;
        .title{
          font-size: 20px;
        }
      }
      .link{
        text-indent: -999999px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  }
}
// .cloud-circle {
//   width: 100%;
//   height: 275px;
//   border-radius: 50%;
//   pointer-events: none;
//   filter: url(#filter);
//   box-shadow: 200px 400px 60px 0px #fff;
//   position: absolute;
//   bottom: 240px;
//   left: 200px;
//   z-index: 99;
//   &.v2{
//     right: 630px;
//     left: unset;
//   }x
// }

.experiencies{
  &__list{
    display: grid;
    margin-top: 4rem;
    margin-bottom: 4rem;
    gap: 3rem;
    grid-template-columns: repeat(3, 1fr);
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }
  .teaser{
    img{
      box-shadow: 3px 3px 10px #00000029;
    }
    &-content{
      margin-top: 10px;
      @include media-breakpoint-down(sm) {
        margin-top: 0;
        padding: 1rem;
        border: 1px solid #eee;
      }
    }
  }
}
.teaser{
  img{
    max-width: 100%;
    height: auto;
    mix-blend-mode: multiply;
  }
  &-content{
    .title{
      font-family: $pacifico;
      font-size: 24px;
      color: theme-color('text-color');
      *{
        font-family: $pacifico;
        font-size: 24px
      }
    }
  }
}

.actus{
  margin-top: 3rem;
  margin-bottom: 3rem;
}


.top_page{
  height: 280px;
  background-size: cover;
  background-position: bottom center;
}


.blog-latest-entries{
  @include make-row();
  .post-item {
    @include make-col-ready();
    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }
    .post-title{
      font-size: 20px;
      text-transform: none;
      line-height: 1.5;
      color: theme-color('dark');
      a{
        color: inherit;
      }

    }
    .post-visual{
      margin-bottom: 1rem;
      box-shadow: 3px 3px 10px #00000029;
      background: white;
    }
    .post-item-wrapper{
      padding: 1rem;
    }
  }
}


.ambassadors{
  background-color: #F9F7F7;
  &__list{
    display: grid;
    @include media-breakpoint-up(lg) {
      grid-template-columns: 50% 50%;
    }
    max-width: 100%;
    .teaser{
      margin-bottom: 4rem;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        text-align: center;

      }
      @include media-breakpoint-only(lg) {
        padding: 2rem;
      }
      .teaser-image{
        padding: 1rem;
        min-width: 30%;
        max-width: 30%;
        @include media-breakpoint-down(lg) {
          width: 100%;
          min-width: 100%;
        }
      }
      h3{
        font-family: $pacifico;
        text-transform: none;
      }
      .link{
        background-color: theme-color('primary');
        color: white;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        border-radius: 14px;
        font-family: $montserrat-bold;
        display: inline-block;
      }
    }
  }
}


.menu--widgets{
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: 1rem;
  figure {
    position: relative;
    float: left;
    overflow: hidden;
    background: theme-color('secondary');
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    img{
      position: relative;
      display: block;
      opacity: 0.7;
      transition: opacity 0.35s, transform 0.35s;
    }
    figcaption{
      color: #fff;
      text-transform: uppercase;
      font-size: 1.25em;
      backface-visibility: hidden;
      &::after, &::before{
        pointer-events: none;
      }

      > div{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2em;
        width: 100%;
        height: 50%;
      }
      a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
      }
    }
    .title{
      font-family: $montserrat-medium;
      color: white;
      transition: transform 0.35s;
      @include rhythm(16);

    }
    .title, p{
      margin: 0;
      transform: translate3d(0,40px,0);
      color: rgba(255,255,255,0.8);
      transition: opacity 0.2s, transform 0.35s;
    }
    p{
      opacity: 0;
      letter-spacing: 1px;
      font-size: 68.5%;
      color: rgba(255,255,255,0.8);
      opacity: 0;
      transition: opacity 0.2s, transform 0.35s;
    }
    &:hover{
      .title, p{
        transform: translate3d(0,0,0);
      }
      p{
        opacity: 1;
        transition-duration: 0.35s;
        transition-delay: 0.05s;
      }
      img{
        opacity: .1;
      }
    }

  }
}


#id_error_page{
  .main-wrap{
    height: calc(100vh - 300px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &::before{
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.06;
      background-image: url(../img/favicon.png);
      background-repeat: no-repeat;
      background-position: center center;

    }
    .container{
      text-align: center;
      position: relative;
      .btn{
        background-color: theme-color('primary');
        color: white;
      }
      h1{
        font-size: 25px;
        color:theme-color('primary');
      }
    }
  }
}
