

@mixin font-size($sizeValue: 14 ){
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.0625 * $sizeValue) + rem;
}

@mixin line-height($heightValue: 20 ){
  line-height: $heightValue + px; //fallback for old browsers
  line-height: (0.0625 * $heightValue) + rem;
}

@mixin rhythm($sizeValue: 12, $heightValue: 20) {
  @include font-size($sizeValue);
  @include line-height($heightValue);
}

h1, h2, h3, h4, h5, h6{
  font-family: $montserrat-bold;
  color: theme-color('text-color');
  &.typo_pacifico{
    text-transform: none;
  }
}
h1, h2{
  text-transform: uppercase;
}


h1{
 // @include font-size(4.4);
  @include rhythm(40, 38);
  @include media-breakpoint-down(sm) {
    @include rhythm(24, 28);
  }
}

h2{
  @include rhythm(32, 38);
  @include media-breakpoint-down(sm) {
    @include rhythm(22, 26);
  }
}
h3{
  @include rhythm(24, 38);
  @include media-breakpoint-down(sm) {
    @include rhythm(20, 24);
  }
}
h4{
  @include rhythm(20, 38);
  @include media-breakpoint-down(sm) {
    @include rhythm(19, 22);
  }
}
h5{
  @include rhythm(19, 28)
}

.typo_pacifico{
  font-family: $pacifico;
  text-transform: none;
}
p.typo_pacifico{
  font-size: 22px;
  line-height: 1.5;
}
p.typo_pacifico_small{
  font-family: $pacifico;
  font-size: 16px;
  line-height: 1.5;
}

.section{
  h2{
    font-size: 25px;
  }
}

.subtitle{
  font-style: italic;
  font-size: 20px;
  color: #AAA7A7;
}
