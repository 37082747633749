:root {
  --colorBlack: #fff;
}
@keyframes translate {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}



.logo{
  display: inline-flex;
  align-items: center;
  color: theme-color('dark');
  &:hover{
    text-decoration: none;
    color: inherit;
  }
  &_text{
    margin-left: 1rem;
  }
  span{
    display: block;
    &:first-child{
      font: normal normal bold 20px/20px  $montserrat-bold;
    }
    &:last-child{
      color: theme-color('green');
      font: normal normal normal 17px/20px $pacifico;;
    }
  }
}
.top_nav{
  background: theme-color('blue');
  color: white;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: $montserrat-medium;
  position: relative;
  .nav{
    height: 40px;
    align-items: center;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  .btn--search{
    background: darken(theme-color('blue'), 3);
    border: 0;
    margin-left: 10px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    svg{
      fill: white;
    }
  }
  a{
    color: white;
    text-align: left;
    transition: all .3s ease;
    @include rhythm(14);
    &:hover{
      text-decoration: none;
      color:theme-color('green');
    }

  }
  .social_links{
    margin-left: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      margin-left: 10px;
    }
    @include media-breakpoint-down(lg) {
      margin-right: 40px;
    }
  }
  nav{
    a{
      @include media-breakpoint-up(lg) {
        margin-left: 20px;
        margin-right: 20px;
      }
      @include media-breakpoint-down(md) {
        margin-left: 12px;
        margin-right: 12px;
        font-size: 14px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

#btn-search_mobile{
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

header#header{
  height: 92px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 20px 20px 20px #00000052;
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 11;
  &.header-scroll{
    left: 0;
    top: 0;
    position: fixed;
    right: 0;
    animation: 1s translate;
  }

  .logo_text{
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  @include media-breakpoint-down(lg) {
    background-color: lighten(theme-color("blue"), 6);
  }
  nav.nav{
    align-items: center;
    ul.menu{
      display: flex;
      flex-direction: row;
      align-items: center;
      .btn-link{
        color: inherit;
      }
      .dropdown-menu{
        border-radius: 0;
        a{
          font-family: $montserrat-medium;
          text-transform: uppercase;
          font-size: 14px;
          &:hover{
            color: theme-color('secondary');
            background-color: transparent;
          }
        }
      }
      @include media-breakpoint-down(lg) {
        opacity: 0;
        pointer-events: none;
        height: 0;
        transition: all .3s ease;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        padding: 10px;
        top: 91px;
        li a{
          display: block;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &.menu--open{
          display: block;
          background-color: #f5F5F5;
          border-top:1px solid #ccc;
          height: 210px;
          opacity: 1;
          pointer-events: all;
        }
      }
      @include media-breakpoint-only(md) {
        text-align: center;
        &.menu--open{
          height: 50px;
        }
        li{
          display: inline-block;
        }
      }
      >li >a{
        color: theme-color('text-color');
        text-transform: uppercase;
        font-family: $montserrat-bold;
        &:hover{
          text-decoration: none;
          color: theme-color('secondary');
        }
      }
      ul{
        display: none;
        position: absolute;
        list-style: none;
        padding-left: 0;
        background-color: white;
        li{
          padding: 10px;
        }
        a{
          text-decoration: none;
        }
      }
      li{

        margin-right: 3rem;
        &:last-child{
          margin-right: 0;
        }
        &:hover{
          ul{
            display: block;
          }
        }
      }
    }
  }
}




// sr-only

.sr-only {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;

  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

// outline

:focus:not(:focus-visible) {
  outline: 0;
}

button:focus {
  outline-offset: 4px;
}

// burger

.burger {
  position: absolute;
  top: 50%;
  right: 0%;

  width: 30px;
  height: 20px;
  padding: 0;

  border: none;
  background-color: transparent;
  box-shadow: inset 0 -2px 0 0 var(--colorBlack);

  transform: translate(-50%, -50%);
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
  }

  &::after,
  &::before {
    position: absolute;

    content: "";
    display: block;
    height: 2px;

    background-color: var(--colorBlack);
  }

  &::before {
    top: 0;
    left: 0;

    width: 100%;
  }

  &::after {
    top: 50%;
    right: 0;

    width: 50%;

    transform: translate(0, -50%);
  }

  &:hover {
    cursor: pointer;
  }
}



.burger--close {
  animation-name: burgerClose;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-direction: reverse;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;

  &::after {
    animation-name: burgerAfterClose;
    animation-duration: 1s;
    animation-direction: reverse;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
  }

  &::before {
    animation-name: burgerBeforeClose;
    animation-duration: 1s;
    animation-direction: reverse;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
  }
}

.sidr-open{
  .burger{
    animation-name: burgerOpen;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    &::after {
      animation-name: burgerAfterOpen;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }

    &::before {
      animation-name: burgerBeforeOpen;
      animation-duration: 1s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes burgerOpen {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }

  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}

@keyframes burgerAfterOpen {
  0% {
    width: 50%;
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(0.6);
  }
}

@keyframes burgerBeforeOpen {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(0.6);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(0.6);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(0.6);
  }

  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(0.6);
  }
}

@keyframes burgerClose {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }

  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}

@keyframes burgerAfterClose {
  0% {
    width: 50%;
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(0.6);
  }

  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(0.6);
  }
}

@keyframes burgerBeforeClose {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(0.6);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(0.6);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(0.6);
  }

  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(0.6);
  }
}



.btn--search-close{
  background: theme-color('primary');
  border: 0;
}


/* ==========================================================================
   Search ui
   ========================================================================== */
   .btn--search {
    font-size: 1.5em;
    svg{
      width: 24px;
      height: 30px;
      fill: theme-color('text-color');
    }
  }

  .btn--hidden {
    pointer-events: none;
    opacity: 0;
  }
  .hidden {
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }


  /* Reset Search Input */

  .search__input , .search__btn{
    border: 0;
    background: transparent;
    border-radius: 0;
    -webkit-appearance: none;
  }

  .search__input:focus, .search__btn:focus {
    outline: none;
  }


/* Layout for search container */
.search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.search {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}

.search::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.9);
}

.btn--search-close {
	font-size: 1.6rem;
	position: absolute;
	top: 0em;
	right: 0em;
	display: none;
  svg{
    fill: theme-color('white');
    width: 40px;
    height: 25px;
  }
}

.btn--search-close {
	display: block;
}

.search__form {
	margin: 5em 0;
  display: flex;
  @include media-breakpoint-only(xs) {
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    .search__input{
      font-size: inherit;
    }
  }
  &:hover{
    .search__btn{
      background-color: theme-color("primary");
      svg{
        fill: white;
      }
    }
  }
}

.search__input{
	font-family: inherit;
	font-size: 2rem;
	line-height: 1;
	display: inline-block;
	box-sizing: border-box;
  letter-spacing: 1px;
	padding: 1rem;
	color: theme-color('primary');
	border: 3px solid ;
  height: 80px;
}
.search__btn{
  border: 3px solid theme-color('primary');
  height: 80px;
  border-left: 0;
  transition: all .3s ease;
  svg{
    transform: rotate(180deg);
    fill: theme-color('primary');
    width: 40px;
    height: 40px;
  }
}

.search__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #fff;
}

.search__input::-moz-placeholder {
	opacity: 1;
	/* Mozilla Firefox 19+ */
	color: #fff;
}

.search__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
	-webkit-appearance: none;
}

.search__input::-ms-clear {
	display: none;
}

.search__info {
	font-size: 90%;
	font-weight: bold;
	display: block;
	width: 75%;
	margin: 0 auto;
	padding: 0.85em 0;
	text-align: right;
	color: theme-color('primary');;
}




/************************/
/* Transitions 			*/
/************************/

body > main {
	position: relative;
	transition: transform 0.5s;
}



.main-wrap--move .btn--search {
	pointer-events: none;
	opacity: 0;
}

.search {
	pointer-events: none;
}

.search--open {
	pointer-events: auto;
  position: fixed;
  top: 0;
  z-index: 9999999 !important;
}

.search::before {
	opacity: 0;
	transition: opacity 0.5s;
}

.search--open::before {
	opacity: 1;
}

/* Close button */
.btn--search-close {
	opacity: 0;
	transition: opacity 0.5s;
  height: 60px;
}

.search--open .btn--search-close {
	opacity: 1;
}

/* Search form with input and description */

.search__suggestion,
.search__info {
	opacity: 0;
	transform: translate3d(0, 50px, 0);
	transition: opacity 0.4s, transform 0.4s;
}

.search--open .search__suggestion,
.search--open .search__info {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}

.search--open .search__suggestion:first-child {
	transition-delay: 0.15s;
}

.search--open .search__suggestion:nth-child(2) {
	transition-delay: 0.2s;
}

.search__input, .search__btn{
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
	transition: transform 0.3s;
}

.search--open .search__input, .search--open .search__btn {
	transform: scale3d(1,1,1);
	transition-duration: 0.5s;
}

@media screen and (max-width: 40em) {
	.btn--search-close {
		font-size: 1.25em;
	}
	.search__suggestion {
		font-size: 80%;
		width: 100%;
	}
	.search__suggestion:nth-child(2),
	.search__suggestion:nth-child(3) {
		display: none;
	}
}
