$fa-font-path: "../fonts/fontawesome/";
$theme-colors: (
  "secondary": #02706A,
  "primary": #C64258,
  "blue": #083D46,
  "green": #7BB540,
  "dark": #363232,
  "text-color": #707070
);

$facebook: #3b5998;
$twitter: #2aa3ef;
$instagram: #b73678;
$youtube: #d34836;
$linkedin: #0073b1;

$border-radius: 14px;

$body-bg: #fff;
$body-color: #707070;



@font-face {
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2');
}
$montserrat-bold: 'Montserrat Bold', sans-serif;

@font-face {
  font-family: 'Montserrat Medium';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2');
}
$montserrat-medium: 'Montserrat Medium', sans-serif;

@font-face {
  font-family: 'Montserrat Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
}
$montserrat-regular: 'Montserrat Regular', sans-serif;

$enable-responsive-font-sizes: true;



@font-face {
  font-family:"Pacifico Regular";
  src: url("../fonts/pacifico/Pacifico-Regular.woff2") format("woff2");
  font-style:normal;font-weight:400;
}
$pacifico: "Pacifico Regular";
