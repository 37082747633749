.slideshow {
  position: relative;

  &-slide {
    background-size: cover;
    background-position: bottom center ;
    height:600px;

    @include media-breakpoint-down(md) {
      display: flex;
      align-items: flex-end;
      padding-bottom: 2rem;
    }

    .img-author-icon{
      position: absolute;
      color: theme-color('primary');
      bottom: 10px;
      left: 10px;
      font-style: normal;
      font-size: 18px;
    }
  }

  .owl-dots, .owl-nav{
    position: absolute;
  }

  .owl-dots{
    bottom: 60px;
    left: 50%;
    transform: translateX(-50px);
  }

  .owl-nav{
    width: 100%;
    top: 50%;
    display: flex;
    z-index: 999999;
    color: black;
    justify-content: space-between;
    pointer-events: none;

    button{
      pointer-events: auto;

      span{
        display: block;
        width: 40px;
        height: 40px;
        color: white;
        font-size: 30px;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.home_carousel{
  position: relative;
  z-index: 0;
  overflow-x: hidden;

  .slideshow{
    @include media-breakpoint-up(lg) {
      margin-top: -45px;
    }

    &-slide{
      @include media-breakpoint-down(md) {
        height: 300px;
      }
    }

    .slide_content{
      @include media-breakpoint-down(md) {
        left: 0;
        top: unset;
        right: 0;
        bottom: 00px;
        max-width: 100%;
        padding: 10px;
        text-align: center;
        h2, h1{
          font-size: 18px;
          line-height: 1;
          color: #222 !important;
        }
        p{
          color: black;
        }
      }
    }

    .owl-item{
      @include media-breakpoint-down(md) {
        height: 610px;
      }
    }

  }
}

.owl-dot {
  margin-left: 5px;
  margin-right: 5px;

  span {
    display: block;
    width: 15px;
    height:15px;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  &.active, &:focus {
    outline: none;
    span {
      background: theme-color('secondary');
      outline: none;
    }
  }
}

.home_carousel .slide_content{
  color: white;
  position: absolute;
  right: 0;
  top: 30%;
  max-width: 40%;
  text-decoration: unset;

  h2, h1 {
    color: white;
    @include rhythm(32, 38);
    @include media-breakpoint-down(sm) {
      @include rhythm(22, 26);
    }
  }
}
.actus{
  .slide_content{
    background-color: rgba(white, .6);
    position: absolute;
    left: 30px;
    top: 40px;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      width: 500px;
      padding: 2rem;

    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      right: 30px;

    }

    &:hover {
      text-decoration: unset;
    }
  }
  h3, p {
    color: theme-color('dark');
    text-transform: none;
  }
  h3 {
    font-family: $pacifico;
  }
}

.carousel.slide {
  .carousel-caption {
    pointer-events: none;
    position: absolute;
    bottom: 0;

    &::after {
      content: "En savoir plus";
      @extend .btn;
      @extend .btn-primary;
      bottom: 2em;
      position: relative;
    }
  }
}
