html, body {
  height: 100%;
  margin: 0;
}
html{
  scroll-behavior: smooth;
 // font-size: 62.5%; /* Sets up the Base 10 stuff */
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $montserrat-regular;
  color: theme-color('dark');
  @include rhythm(16, 23);
}


main.page {
  // min-height: 100%;
  @include media-breakpoint-down(lg) {
    overflow-x: hidden;
  }

  &::after{
    content: "";
    display: block;
  }
}

.blog-visual{
  img{
    max-width: 100%;
    height: auto;
  }
}

.app-blog{
  h1{
    margin-bottom: 3rem;
    font-size: 30px;
  }
  .list-inline {
    &-item{
      display: inline-flex;
      align-items: center;
    }
  }
  .post-item{
    margin-top: 30px;
  }
}

.blog-list{

  .post-item{
    .post-item-wrapper{
      background: #fff ! important;
      box-shadow: 0 25px 50px 0 rgba(28,15,125,0.08);
      height: 100%;
    }
    .post-title{
      font-size: 20px;
    }
    .post-visual{
      margin-bottom: 0.5rem;
    }
    .post-content{
      padding: 1.5rem;
    }
  }
}


.post-header, .post-lead {
  a{
    color:theme-color('text-color');
  }
}

.blog-list{
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.breadcrumb{
  background-color: transparent;
  padding-left: 0;
  border-bottom: 1px solid #eee;
  border-radius: 0;
}

.post-item.featured{
  width: 100%;
}


.blog-visual{
  margin-bottom: 2rem;
}
.categories{
  .blog-categories{
    padding-left: 0;
    list-style: none;
    li{
      display: inline-block;
      margin: 0 10px 0 0px ;
      margin-bottom: 20px;
      height: 35px;
      line-height: 35px;
      text-transform: uppercase;
      padding: 0 20px;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      font-weight: 700;
      background-color: theme-color('secondary');
      transition: .2s ease all;
      cursor: pointer;
      letter-spacing: 1px;
      a{
        color: white;
        span{
          display: none;
        }
        &:hover{
          color: theme-color('primary');
          text-decoration: none;
        }
      }
    }
  }
}

.img-align-center{
  text-align: center;
}

.btn{
  font-family: $montserrat-bold;
}


.scroll_to_top{
  position: fixed;
  right: 50px;
  bottom: 10%;
  background-color: theme-color('text-color');
  color: white;
  padding: 1rem;
  border-radius: 4px;
  transition: all .3s ease;
  z-index: 999;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }

  &:hover{
    color: white;
    background-color: theme-color('primary');
  }
}



img{
  &.align-center{
    margin: 0 auto;
    display: block;
  }
  &.align-left{
    text-align: left;
  }
  &.align-right{
    text-align: right;
  }

}
a{
  transition: all .3s ease;
}


#id_legalnotice{
  .main-wrap{
    padding-top: 2rem;
    h1,h2, h3{
     text-transform: none;
     color: inherit;
    }
    h2{
      @include media-breakpoint-up(lg) {

        font-size: 24px;
      }
     color: theme-color('secondary');
    }
  }
  ol.legalnotice-list{
    margin-top: 2rem;
    list-style-type: none;
    padding-left: 0;
    >li{
      margin-bottom: 2rem;
      border-bottom: 1px dashed #eee;
      padding-bottom: 2rem;

    }

  }
}


.breadcrumb{
  li{
    font-size: 13px !important;
    font-family: $montserrat-medium;
    *{
      font-size: 13px !important;
    }
  }
  li+li{
    padding-left: 0.5rem;
    &::before{
      float: left;
      padding-right: .5rem;
      color: #6c757d;
      content: var(--bs-breadcrumb-divider, "/");
    }
  }
}


header.header-scroll + main.page{
  padding-top: 200px;
}



img[alt=""],
img:not([alt]){
  border: 2px solid #c00;
}
